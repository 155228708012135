import React from 'react';
import Layout from '../components/App/Layout';
import { isIOS, isAndroid } from 'react-device-detect';
import { getDownloadUrlByOsVersion } from '../utils/redirect';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import SEO from '../components/App/SEO';
import {Link} from 'gatsby';
import { graphql } from 'gatsby'
import ClickAnalytics from "../components/CampaignAnalytics/ClickAnalyticsGeneral"

const redirectPage = ({data}) => {
    if(isIOS){
        window.location.replace('https://apps.apple.com/us/app/blue-mail-email-mailbox/id1063729305');
    }else if(isAndroid){
        window.location.replace('https://play.google.com/store/apps/details?id=me.bluemail.mail');
    }

    return (
        <Layout env={data.site.siteMetadata.env}>
            <Navbar />
            <SEO 
                postTitle='Download Today | BlueMail App' 
                postDescription='Download BlueMail Today'
                postImage=''
                postURL='r'
                postSEO
            />
            <div className="container pt-120 pb-120">
                <div className='row'>
                    <div className='col-lg-12'>
                        <h1 className="center-text mb-50">Download BlueMail Today</h1>
                    </div>
                </div>
                <div style={{display: 'flex',flexDirection: 'column', textAlign: 'center'}}>
                    <a href={getDownloadUrlByOsVersion()} style={{margin: '10px 10px'}} target='_blank' rel="noreferrer"><img src={`/img/Download-BlueMail-Windows.svg`} style={{maxWidth: '200px'}} alt='BlueMail Windows Download' /></a>
                    <a href='https://apps.apple.com/us/app/bluemail/id1458754578?mt=12' style={{margin: '10px 10px'}} target='_blank' rel="noreferrer"><img src={`/img/BlueMail-MacOS-Badge.svg`} style={{maxWidth: '200px'}} alt='BlueMail Mac Download' /></a>
                    <Link to='/desktop/linux/' style={{margin: '10px 10px'}}><img src={`/img/BlueMail-Linux-Badge.svg`} style={{maxWidth: '200px'}} alt='BlueMail Linux Download' /></Link>
                    <a href='https://apps.apple.com/us/app/blue-mail-email-mailbox/id1063729305' style={{margin: '10px 10px'}} target='_blank' rel="noreferrer"><img src={`/img/BlueMail_AppStore.svg`} style={{maxWidth: '200px'}} alt='BlueMail iOS Download' /></a>
                    <a href='https://play.google.com/store/apps/details?id=me.bluemail.mail' style={{margin: '10px 10px'}} target='_blank' rel="noreferrer"><img src={`/img/BlueMail_PlayStore.svg`} style={{maxWidth: '200px'}} alt='BlueMail Android Download' /></a>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default redirectPage;

export const query = graphql`
query RedirectPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`